import * as React from "react"

import Inner from "~/components/Organisms/inner"
import ContentsTextBlock from "~/components/Molecules/contentsBlock"
import ContentsFooter from "~/components/Molecules/contentsFooter"
import Slider from "~/components/Molecules/works/slider"

import WorksItems from "~/components/Molecules/works/worksItems"

import Data from "~/assets/data/stage2.json"
import Kv from "~/components/Molecules/works/kv"

import Layout from "~/components/Organisms/layout"
import Seo from "~/components/seo"

const imageKv = "/images/stage2/stage2-kv.jpg"
const stage2 = () => (
  <Layout>
    <Seo title="番外公演Vol.1『ちょっと愉快な授業報告』" />
    <Kv
      data={Data}
      img={imageKv}
    />
    <Inner>
      <ContentsTextBlock 
        title={Data.about.title}
        lead={Data.about.lead}
      />
      <Slider
        title='ギャラリー'
        imageNum='6'
        stageNum='2'
      />
      <ContentsTextBlock 
        title={Data.staff.title}
        lead={Data.staff.lead}
      />
      <WorksItems/>
    </Inner>
    <ContentsFooter />
  </Layout>
)

export default stage2
